import styles from './Page.module.scss';

import dynamic from 'next/dynamic';
import typePage from '@/utilities/types/Page';
import typeSettings from '@/utilities/types/Settings';
import { Fragment } from 'react';

const TitleNavigation = dynamic(
  () => import('@/components/molecules/TitleNavigation'),
);
const Article = dynamic(() => import('@/components/organisms/Article'));
const Block = dynamic(() => import('@/components/organisms/Block'));
const Cta = dynamic(() => import('@/components/organisms/Cta'));
const Hero = dynamic(() => import('@/components/organisms/Hero'));
const Media = dynamic(() => import('@/components/organisms/Media'));
const Notification = dynamic(
  () => import('@/components/organisms/Notification'),
);
const Pricing = dynamic(() => import('@/components/organisms/Pricing'));
const Slider = dynamic(() => import('@/components/molecules/Slider'));
const AppFeatures = dynamic(() => import('@/components/organisms/AppFeatures'));

interface PageProps {
  content: {
    page: typePage;
  };
}

const Page = (props: PageProps) => {
  const { content } = props;

  const settings: typeSettings = {
    id: content.page.pageId,
    title: content.page.title,
  };

  return (
    <div className={styles.root}>
      {content.page.acfPage.section.map((section, index) => (
        <Fragment key={index}>
          {section.article && (
            <Article
              archive={content.page.children.nodes}
              article={section.article}
              index={index}
            />
          )}
          {section.block && (
            <Block
              block={section.block}
              index={index}
              priority={index === 0 ? true : false}
              settings={settings}
              tag={index === 0 ? 'h1' : 'h2'}
            />
          )}
          {section.contentNavigation && (
            <TitleNavigation
              labels={
                content.page.acfPage.section
                  .map((s) => s.block?.id)
                  .filter(Boolean) as string[]
              }
            />
          )}
          {section.cta && <Cta columns={10} cta={section.cta} index={index} />}
          {section.hero && <Hero hero={section.hero} index={index} />}
          {section.media && (
            <Media
              id={section.id}
              index={index}
              media={section.media}
              settings={settings}
            />
          )}
          {section.notification && (
            <Notification index={index} notification={section.notification} />
          )}
          {section.appFeatures && (
            <AppFeatures index={index} content={section.appFeatures} />
          )}
          {section.slider && <Slider content={section.slider} />}
          {section.pricing && (
            <Pricing content={section.pricing} index={index} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Page;
